.pain-level {
  display: flex;

  .pain {
    width: 38px;
    height: 38px;
    min-width: 38px;
    min-height: 38px;
    margin-right: 5px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    cursor: pointer;
    border: 2px solid #e6e6e6;
  }

  .level-1 {
    border-color: #85c14b;

    &.active {
      background-color: #85c14b;
    }
  }

  .level-2 {
    border-color: #95b634;

    &.active {
      background-color: #95b634;
    }
  }

  .level-3 {
    border-color: #95b634;

    &.active {
      background-color: #95b634;
    }
  }

  .level-4 {
    border-color: #b09c01;

    &.active {
      background-color: #b09c01;
    }
  }

  .level-5 {
    border-color: #bb8e00;

    &.active {
      background-color: #bb8e00;
    }
  }

  .level-6 {
    border-color: #c47f00;

    &.active {
      background-color: #c47f00;
    }
  }

  .level-7 {
    border-color: #cc6f04;

    &.active {
      background-color: #cc6f04;
    }
  }

  .level-8 {
    border-color: #d25e18;

    &.active {
      background-color: #d25e18;
    }
  }

  .level-9 {
    border-color: #d64b27;

    &.active {
      background-color: #d64b27;
    }
  }

  .level-10 {
    border-color: #d73535;

    &.active {
      background-color: #d73535;
    }
  }

  .level-selected-color {
    background-color: #0071ff;
    border: 1px solid #0071ff;
    color: white;
  }
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 575.98px) {
  .user-address {
    font-size: 14px;
    word-break: break-word;
  }

  .pain-level {
    display: flex;

    .pain {
      width: 38px;
      height: 38px;
      min-width: 38px;
      min-height: 38px;
      margin-right: 5px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}
